import { ReactNode, useCallback, useState } from "react";

import { min } from "lodash-es";
import { useTranslation } from "next-i18next";

import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import { TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";

const isOnlyChecked = (checked: Record<string, boolean>, key: string) => {
  return checked[key] && Object.values(checked).filter((o) => o).length === 1;
};

export interface CheckboxListItem {
  value: string;
  label: string;
  itemDisplay: (selected: Record<string, boolean>) => ReactNode;
}

export type CheckboxListProps = {
  items: Array<CheckboxListItem>;
  onSelectionChange: (selected: Record<string, boolean>) => void;
  showItemsPer?: number;
};

// A component for a checkbox list.
// Inspired by MUI: https://mui.com/material-ui/react-list/#list-controls
// I've also made it so that it can take a list of items
export function CheckboxList({ items, onSelectionChange, showItemsPer = 10 }: CheckboxListProps) {
  const { t } = useTranslation("common");
  loadTranslations("common");

  const [state, setState] = useState(
    items.reduce(
      (res, item) => {
        res[item.value] = true;
        return res;
      },
      {} as Record<string, boolean>,
    ),
  );

  const handleClick = useCallback(
    (value: string) => () => {
      setState((values) => {
        const onlyChecked = isOnlyChecked(values, value);
        if (onlyChecked) {
          Object.keys(values).forEach((key) => (values[key] = true));
        } else {
          Object.keys(values).forEach((key) => (values[key] = false));
          values[value] = true;
        }
        onSelectionChange(values);
        return values;
      });
    },
    [setState, onSelectionChange],
  );

  const handleChange = useCallback(
    (value: string) => () => {
      setState((values) => {
        values = {
          ...values,
          [value]: !values[value],
        };
        onSelectionChange(values);
        return values;
      });
    },
    [setState, onSelectionChange],
  );

  const [shownItems, setShownItems] = useState(showItemsPer);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <FormControl component="fieldset">
        <FormGroup>
          <List sx={{ width: "100%" }}>
            {items.slice(0, shownItems).map(({ value, itemDisplay }) => {
              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton
                    role={undefined}
                    dense
                    sx={{
                      "& .MuiFormControlLabel-root": { marginRight: "0px" },
                    }}
                  >
                    <FormControlLabel
                      value={value}
                      control={
                        <Checkbox
                          edge="start"
                          disableRipple
                          checked={!!state[value]}
                          name={value}
                          inputProps={{ "aria-labelledby": value }}
                          size="small"
                          sx={{
                            "& .MuiSvgIcon-root": { width: 36 },
                          }}
                          onChange={handleChange(value)}
                        />
                      }
                      label=""
                      tabIndex={-1}
                      disabled={items.length === 1}
                    />
                    <Box
                      sx={{
                        "&:hover .checkbox-item-hover": {
                          display: "block",
                        },
                      }}
                      onClick={items.length === 1 ? () => {} : handleClick(value)}
                      width="100%"
                    >
                      {itemDisplay(state)}
                    </Box>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </FormGroup>
      </FormControl>

      {shownItems < items.length && (
        <Button
          variant="text"
          onClick={() => setShownItems((prev) => min([prev + showItemsPer, items.length]) || items.length)}
        >
          {t("actions.show_more")}
        </Button>
      )}
    </Box>
  );
}

export interface CheckboxListWithCountItem {
  value: string;
  label: string;
  count: number;
  color?: string;
  backgroundColor?: string;
}

export type CheckboxListWithCountProps = {
  items: Array<CheckboxListWithCountItem>;
  onSelectionChange: (selected: Record<string, boolean>) => void;
};

// cf CheckboxList for description.
// This is just CheckboxList with additional logic to display the badge number next to the labels.
export function CheckboxListWithCount({ items, onSelectionChange }: CheckboxListWithCountProps) {
  const theme = useTheme();
  const { t } = useTranslation("common");
  loadTranslations("common");

  return (
    <CheckboxList
      items={items.map((item) => ({
        value: item.value,
        label: item.label,
        itemDisplay: (selected) => (
          <ListItemText id={item.value} role="row" aria-label="filtre sélection">
            <Box display="flex" justifyContent="space-between">
              <span
                role="gridcell"
                aria-label="filtre label"
                style={{ paddingRight: 20, color: theme.palette.grey[600], whiteSpace: "nowrap", overflow: "hidden" }}
              >
                <Typography
                  variant="tags"
                  color={TEXT_COLOR.mainInfo}
                  sx={{
                    display: "block",
                    maxWidth: "20ch",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {item.label}
                </Typography>
              </span>
              <Box display="flex">
                <Box
                  className="checkbox-item-hover"
                  sx={{
                    marginRight: 4,
                    display: "none",
                    color: theme.palette.grey[600],
                  }}
                >
                  {isOnlyChecked(selected, item.value) ? t("common:all") : t("common:only")}
                </Box>
                <Box
                  sx={{
                    marginRight: "5px",
                  }}
                >
                  {selected[item.value] ? (
                    <Badge
                      badgeContent={<Typography variant="tags">{item.count}</Typography>}
                      showZero
                      max={999}
                      role="gridcell"
                      aria-label="filtre compte"
                      sx={{
                        marginRight: 1,
                        "& .MuiBadge-badge": {
                          backgroundColor: item.backgroundColor || theme.palette.primary[100],
                          color: item.color || theme.palette.primary[800],
                        },
                      }}
                    />
                  ) : (
                    <Badge badgeContent={"-"} showZero max={999} role="gridcell" aria-label="filtre compte" />
                  )}
                </Box>
              </Box>
            </Box>
          </ListItemText>
        ),
      }))}
      onSelectionChange={onSelectionChange}
    />
  );
}
