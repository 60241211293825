import Image from "next/image";
import { useRouter } from "next/router";

import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { signIn, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Box, Container, Typography } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useMutation } from "@tanstack/react-query";

import { InfoBox } from "@work4Labs/design-system";

import { UpdateUserInfoBody, userApi } from "@api";
import { PhoneInput, Spinner } from "@components";
import { Roles } from "@constants";
import { loadTranslations } from "@lib";
import { hasRole } from "@utils";

import { usePhoneNumberVerificationStatus } from "@hooks/queries";

import Logo from "../../public/assets/logo.svg";

export const WithOnboarding: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const { data: session } = useSession();
  const { t } = useTranslation(["profile-validation", "onboarding"]);
  loadTranslations("profile-validation");
  loadTranslations("onboarding");

  const [success, setSuccess] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const phoneNumberVerificationStatus = usePhoneNumberVerificationStatus(session?.user.phone || "");

  const { mutate } = useMutation({
    mutationFn: (data: Partial<UpdateUserInfoBody>) => userApi.updateUserInfo(context.active(), data),
    onSuccess: async () => {
      // This sign-in enables to refresh token information:
      // https://github.com/nextauthjs/next-auth/issues/2269
      await signIn("keycloak", { callbackUrl: router.pathname });
      setSuccess(true);
      toast.success(t("updated_data"));
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  useEffect(() => {
    if (
      session == null ||
      session.error ||
      phoneNumberVerificationStatus.isLoading ||
      phoneNumberVerificationStatus.error != null
    ) {
      return;
    }

    // Do not force number validation for super-admins.
    if (hasRole(session, Roles.SUPER_ADMIN)) {
      return;
    }

    if (!session.user.phone || !phoneNumberVerificationStatus.verified) {
      setShowOnboarding(true);
    }
  }, [
    session,
    phoneNumberVerificationStatus.verified,
    phoneNumberVerificationStatus.isLoading,
    phoneNumberVerificationStatus.error,
  ]);

  const updatePhone = useCallback(
    ({ personal_phone }: { personal_phone: string }) => {
      mutate({ phone: personal_phone });
    },
    [mutate],
  );

  const customSuccessButton = useMemo(
    () => ({
      children: t("onboarding:button.finish"),
      action: () => {
        setShowOnboarding(false);
      },
    }),
    [t],
  );

  if (phoneNumberVerificationStatus.isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner style={{ position: "relative", left: 0, top: 0 }} />
      </div>
    );
  }

  if (showOnboarding) {
    return (
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "45rem", maxWidth: "100%", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <Box
            sx={{
              width: "100%",
              paddingBottom: "2.5rem",
              borderStyle: "solid",
              borderWidth: "0 0 1px 0",
              borderColor: "var(--border-input-base)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Image src={Logo} alt="logo" style={{ height: "1.5rem", width: "auto" }} />
          </Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Typography variant="h1" sx={{ fontSize: "2rem", fontWeight: 800, color: "var(--text-color-main-info)" }}>
              {t("onboarding:title")}
            </Typography>
            <Typography sx={{ fontWeight: 400, color: "var(--text-color-main-info)", fontSize: "1rem" }}>
              {t("onboarding:phone.subTitle")}
            </Typography>
          </Box>
          <InfoBox
            title={t(`onboarding:phone.infobox.${success ? "success" : "default"}.title`)}
            level={success ? "success" : "info"}
          >
            {t(`onboarding:phone.infobox.${success ? "success" : "default"}.content`)}
          </InfoBox>

          <PhoneInput
            name="personal_phone"
            defaultValue={session?.user.phone || ""}
            onSubmit={updatePhone}
            label={t("onboarding:label.phone")}
            placeholder={t("onboarding:label.phone")}
            required
            forceValidation
            forceFocus
            customSuccessButton={customSuccessButton}
          />
        </Box>
      </Container>
    );
  }

  return <>{children}</>;
};
