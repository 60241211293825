import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";

import { uniq } from "lodash-es";
import { Trans, useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { FormatListBulleted as FormatListBulletedIcon } from "@mui/icons-material";
import { Button, Stack, ThemeProvider, useTheme } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import { InfoBox, Modal } from "@work4Labs/design-system";

import { loadTranslations } from "@lib";
import { ApplicationListItem, ApplicationStatusUpdateParams, Status } from "@typings";
import { Logger } from "@utils";

import { SelectApplicationStatus, updateApplicationStatus } from "../../common";

type BulkApplicationStatusUpdateModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  applications: ApplicationListItem[];
  onSuccess: () => void;
};

export const BulkApplicationStatusUpdateModal: FC<BulkApplicationStatusUpdateModalProps> = ({
  open,
  setOpen,
  applications,
  onSuccess,
}) => {
  const { t } = useTranslation(["bulk-update-modal"]);
  loadTranslations("bulk-update-modal");

  const theme = useTheme();
  const queryClient = useQueryClient();

  const campaignIDs = useMemo(() => uniq(applications.map((application) => application.campaign_id)), [applications]);

  const applicationsHaveMultipleConfigurations = campaignIDs.length > 1;

  const [newStatus, setNewStatus] = useState<Status | null>(null);
  const [params, setParams] = useState<ApplicationStatusUpdateParams>({} as ApplicationStatusUpdateParams);

  const onStatusChange = useCallback((newStatus: Status, params: ApplicationStatusUpdateParams) => {
    setNewStatus(newStatus);
    setParams(params);
  }, []);

  // On form submit, we create a list of promises to update the statuses and create the comments.
  // We then run all queries in // and show a toast when we're done.
  const onStatusConfirm = useCallback(
    async (newStatus: Status, params: ApplicationStatusUpdateParams) => {
      // don't wait for query before closing because we don't invalid the submit button yet
      setOpen(false);

      // The product wants us to first change the statuses and then create the comments.
      const statusPromises = applications
        .filter((application) => application.status !== newStatus?.label)
        .map((application) =>
          updateApplicationStatus({
            applicationID: application.id,
            queryClient: queryClient,
            newStatus,
            updateParams: params,
            isBulkAction: true,
          }).catch((error) => {
            // Keep a trace of the errors.
            Logger.error(error);
            throw error;
          }),
        );

      const statusResults = await Promise.allSettled(statusPromises);

      const failureCount = statusResults.filter((r) => r.status === "rejected").length;

      const successCount = statusResults.length - failureCount;

      if (failureCount == 0) {
        // Only do the end part if we've updated at least one application in the first place
        // We do this because the product doesn't want success notification if nothing got updated
        // (some update can be skipped, like the status update for an application already in status).
        if (successCount > 0) {
          toast.success(t("bulk_action_done"));
          onSuccess();
        }
      } else {
        toast.error(t("bulk_action_error", { count: failureCount }));
      }
    },
    [applications, queryClient, onSuccess, setOpen, t],
  );

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirm = useCallback(() => {
    if (newStatus == null) {
      return;
    }
    onStatusConfirm(newStatus, params);

    setOpen(false);
  }, [onStatusConfirm, newStatus, params, setOpen]);

  const customActions = useCallback(
    (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
      const buttonStyle = {
        fontSize: "1rem",
        fontWeight: "600",
        textTransform: "none",
        borderRadius: "8px",
      };
      return (
        <>
          <Button
            color="secondary"
            onClick={() => {
              onClose?.();
            }}
            sx={{
              ...buttonStyle,
              color: theme.palette.primary.main,
              backgroundColor: "white",
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              onConfirm?.();
            }}
            sx={{
              ...buttonStyle,
              color: "white",
              backgroundColor: theme.palette.color.deepPurple,
              "&:hover": {
                backgroundColor: theme.palette.color.deepPurple,
              },
            }}
          >
            {t("submit")}
          </Button>
        </>
      );
    },
    [t, theme.palette.color.deepPurple, theme.palette.primary.main],
  );

  return (
    <Modal
      isOpen={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      modalTitle={t("modal_title")}
      modalIcon={<FormatListBulletedIcon />}
      title={t("title")}
      // onConfirm, update the status in the back
      onConfirm={onConfirm}
      onClose={onCancel}
      customActions={customActions}
    >
      <ThemeProvider theme={theme}>
        <Stack direction="column" gap="1rem" alignSelf="stretch" sx={{ width: "100%" }}>
          {applicationsHaveMultipleConfigurations ? (
            <InfoBox title={t("bulk_update.jobs_warning.title")} level={"warning"}>
              <Trans
                t={t}
                i18nKey="bulk_update.jobs_warning.content"
                // eslint-disable-next-line react/jsx-key
                components={[<strong key={0} />]}
                values={{
                  count: applications.length,
                }}
              />
            </InfoBox>
          ) : null}
          <InfoBox title={""} level={"info"}>
            <Trans
              t={t}
              i18nKey="bulk_update.info"
              // eslint-disable-next-line react/jsx-key
              components={[<strong key={0} />]}
              values={{
                count: applications.length,
              }}
            />
          </InfoBox>
        </Stack>

        <SelectApplicationStatus
          campaignIDs={campaignIDs}
          customLabel={t("status_field_label")}
          // onChange, save the selected statuses in a state
          onChange={onStatusChange}
          dropDownScaleWidth={1}
          applications={applications}
          onStatusConfirm={(newStatus, params) => {
            onStatusConfirm(newStatus, params).catch(Logger.error);
          }}
          sendSMS={true}
        />
      </ThemeProvider>
    </Modal>
  );
};
